body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: pink;
  min-height: 100vh;
}

table {
  width: 500px;
  text-align: left;
}

button {
  border-radius: 4px;
  border: 1px solid blue;
  color: blue;
  background-color: transparent;
  padding: 10px;
}
