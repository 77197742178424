.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.is-365primary {
  background-color: pink;
}

button.is-moneyhub {
  border-color: transparent;
  color: #fff;
  background-color: #4A8290;
  width:500px;
}

button.is-moneyhub:hover {
  color: #fff;
  background-color: rgb(2, 131, 146);
  border-color: rgb(2, 131, 146);
}

button.is-moneyhub img {
  height: 32px;
}

.hero-head {
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

.currency-selector {
  padding-left: env(safe-area-inset-left);
}

.section {
  padding: 0.5rem 1rem;
}

.navbar-bottom-button {
  padding-bottom: env(safe-area-inset-bottom);
}

.navbar-bottom.is-white {
  background-color: white;
}

.navbar-item img {
  max-height: unset;
  border-radius: 4rem;
}

.card {
  margin-bottom: 1rem;
}

.box-button {
  white-space: normal;
}
.nowrap {
  white-space: nowrap;
}

.v-align {
  display: flex;
  align-items: center;
}

.version {
  opacity: 0.2;
  text-align: right;
  position: relative;
  bottom: -1.5rem;
  height: 0px;
}

.preserve-whitespace {
  white-space: pre;
}

.is-clickable {
  padding-bottom: 0.4rem;
  border-bottom: rgba(10, 10, 10, 0.2) solid 1px;
}
.currency-selector {
  /* margin-left: 7vw; */
}
.currency-selector select {
  margin: 0.2rem;
  width: 6rem;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0px;
  appearance: none;
  outline: none;
  padding: 2px 30px 2px 2px;
}

.currency-selector::after {
  display: none;
}

.currency-selector-line {
  margin-left: 0.4rem;
  height: 0;
  width: 3.3rem;
  border-bottom: rgba(10, 10, 10, 0.2) solid 1px;
}

.progress.is-subtle {
  height: 0.15rem;
  border: none;
  border-radius: unset;
}

.user-plan-title {
  font-weight: 400;
}

.user-plan-title span {
  font-weight: 700;
}

.columns.is-variable .column.catchup-column {
  padding-right: 0;
  margin-right: 0;
}
.level-item.catchup-amount {
  justify-content: unset;
  white-space: pre;
}
.level-item.catchup-amount strong {
  display: content;
}
